import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core';

import { sizes as s } from '../../assets/sizes/sizes';
import { colors as c } from '../../assets/colors/colors';

interface OwnProps {
    img: string;
    title: string;
}

type Props = OwnProps;

const HeaderImg = ({ img, title }: Props) => {
    const headerImg = createStyles({
        bgImg: {
            display: 'flex',
            alignItems: 'center',
            padding: '0 14%',
            backgroundImage: `url(${img})`,
            backgroundPosition: 'center',
            height: '320px',
            [`@media (max-width: ${s.mobileL})`]: { height: '280px' },
        },
        title: {
            fontSize: '36px',
            [`@media (max-width: ${s.mobileL})`]: { fontSize: '28px' },
            color: c.white,
            textShadow: '0px 3px 6px #00000029',
            fontWeight: 500,
        },
    });

    const useStyles = makeStyles(headerImg);
    const sty = useStyles();

    return (
        <div className={sty.bgImg}>
            <div className={sty.title}>
                {title}
            </div>
        </div>
    );
};

export default HeaderImg;