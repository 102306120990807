/* eslint-disable no-useless-computed-key */
import { createStyles } from '@material-ui/core';
import { colors as c } from '../../../assets/colors/colors';
import { sizes as s } from '../../../assets/sizes/sizes';

const tabStyle = createStyles({
    tabView: {
        display: 'flex',
        padding: '0 8px',
        [`@media (max-width: ${s.tablet})`]: { display: 'none' },
    },
    link: {
        display: 'flex',
        alignItems: 'center',
        '&,&:hover,&:focus': {
            color: c.black,
            textDecoration: 'none',
        },
    },
    dot: {
        width: '5px',
        height: '5px',
        borderRadius: '5px',
        backgroundColor: c.primary,
    },
    dotHidden: {
        width: '5px',
        height: '5px',
    },
    tab: {
        padding: '10px 14px',
        cursor: 'pointer',
    },
    tabInactive: {
        color: c.black,
    },
    tabActive: {
        color: c.primary,
        fontWeight: 'bold',
    },
});

export default tabStyle;
