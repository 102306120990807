export const colors: any = {
    primary: '#21A0CE',
    secondary: '#148FBC',
    bgLightColor: '#eaf1f9',
    bgDarkColor: '#d5e9f3',
    bgFaq: '#f6f9fb',
    bgIcons: '#E9F1F8',
    white: '#ffffff',
    black: '#484848',
    lightGray: '#D5D5D5',
    mediumGray: '#b2b2b2',
};