/* eslint-disable no-useless-computed-key */
import { createStyles } from '@material-ui/core';
import Background from '../../assets/img/homeBg.jpg';
import { colors as c } from '../../assets/colors/colors';
import { sizes as s } from '../../assets/sizes/sizes';

const mainStyle = createStyles({
    main: {
        backgroundSize: 'cover',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '93vh',
        [`@media (max-width: ${s.mobileL})`]: { height: '90vh' },
        backgroundImage: `url(${Background})`,
        backgroundPosition: 'top',
        [`@media (min-width: ${s.mobileL}) and (max-width: ${s.laptop})`]: { backgroundPosition: '30% 50%' },
        [`@media (max-width: ${s.mobileL})`]: { backgroundPosition: '35% 50%' },
    },
    middle: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        justifyContent: 'space-between',
        padding: '3%',
        height: '230px',
        [`@media (min-width: ${s.tablet}) and (max-width: ${s.laptop})`]: { height: '300px' },
        [`@media (min-width: ${s.mobileL}) and (max-width: ${s.tablet})`]: { height: '250px' },
        [`@media (max-width: ${s.mobileL})`]: { height: '200px' },
    },
    title: {
        color: c.white,
        textAlign: 'center',
        fontWeight: 'bold',
        textShadow: '0px 3px 6px #00000029',
        fontSize: '42px',
        [`@media (min-width: ${s.mobileL}) and (max-width: ${s.tablet})`]: { fontSize: '32px' },
        [`@media (max-width: ${s.mobileL})`]: { fontSize: '26px' },
    },
    subTitle: {
        textAlign: 'center',
        color: c.white,
        textShadow: '0px 3px 6px #00000029',
        fontSize: '32px',
        [`@media (min-width: ${s.mobileL}) and (max-width: ${s.laptop})`]: { fontSize: '24px' },
        [`@media (min-width: ${s.mobileM}) and (max-width: ${s.mobileL})`]: { fontSize: '22px' },
        [`@media (max-width: ${s.mobileS})`]: { fontSize: '18px' },
    },
    bgDivider: {
        backgroundColor: c.lightGray,
        width: '50px',
    },
    type: {
        display: 'flex',
    },
});

export default mainStyle;
