import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { messages as m } from '../../../assets/messages/messages';
import { useHistory } from "react-router-dom";

import styles from './buttonStyle';

const useStyles = makeStyles(styles);

interface OwnProps {
    bigger?: boolean;
}

type Props = OwnProps;

const PrimaryButton = ({ bigger }: Props) => {
    const sty = useStyles();

    const history = useHistory();

    const routeChange = () => {
        let path = m.tryForFreePath;
        history.push(path);
    }

    return (
        <Button onClick={routeChange} className={bigger ? `${sty.button} ${sty.bigger}` : `${sty.button} ${sty.normal} `} variant="contained" color="primary">{m.tryForFree}</Button>
    );
};

export default PrimaryButton;