import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Computer from '../../assets/img/computer.png';
import PrimaryButton from '../Buttons/PrimaryButton';

import { messages as m } from '../../assets/messages/messages';
import styles from './tryItStyle';

const useStyles = makeStyles(styles);
interface OwnProps {
    animate?: boolean;
}
type Props = OwnProps;

const TryIt = ({ animate }: Props) => {
    const sty = useStyles();

    return (
        <div className={sty.tryItView}>
            <div className={sty.imgView}>
                <img className={sty.img} src={Computer} alt={''} data-aos={animate && ("fade-right")} />
            </div>
            <div className={sty.formView}>
                <div className={sty.title}>
                    {m.tryForFreeTitle}
                </div>
                <p className={sty.subtitle}>
                    {m.tryFfLine1}
                    <br></br>
                    {m.tryFfLine2}
                    <br></br>
                    {m.tryFfLine3} <strong>contato@tecnobil.com.br</strong>
                </p>
                { /* 
                <div className={sty.fieldsView}>
                    <input placeholder={m.name} className={sty.input} type="text"></input>
                    <input placeholder={m.mail} className={sty.input} type="text"></input>
                    <input placeholder={m.phone} className={sty.input} type="number"></input>
                </div>
                <div>
                    <PrimaryButton bigger />
                </div>
                */ }
                <div className={sty.fieldsView}>
                    <br /><br /><br /><br /><br /><br /><br /><br /><br />
                </div>
            </div>
        </div>
    );
};

export default TryIt;