import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";

import { messages as m } from "../../../assets/messages/messages";
import styles from "./drawerStyle";

interface OwnProps {
    setMenuIsOpen(menuIsOpen: boolean): void;
    menuIsOpen: boolean;
}

type Props = OwnProps;

const useStyles = makeStyles(styles);

const MenuDrawer = ({ setMenuIsOpen, menuIsOpen }: Props) => {
    const sty = useStyles();

    const menuItens: any = [
        {
            id: 0,
            name: m.home,
            path: m.homePath,
        },
        {
            id: 1,
            name: m.modules,
            path: m.modulesPath,
        },
        /*
        {
            id: 2,
            name: m.pricesAndPlans,
            path: m.pricesAndPlansPath,
        },
        */
        {
            id: 3,
            name: m.tryForFree,
            path: m.tryForFreePath,
        }
    ]

    return (
        <Drawer
            anchor={"left"}
            open={menuIsOpen}
            onClose={() => setMenuIsOpen(false)}
        >
            <List className={sty.list}>
                {menuItens.map((item: any) =>
                    <div key={item.id} onClick={() => setMenuIsOpen(false)}>
                        <Link className={sty.white} to={item.path}>
                            <ListItem button key={item.id}>
                                <ListItemText primary={item.name} />
                            </ListItem>
                        </Link>
                        <Divider className={sty.bgDivider} />
                    </div>
                )}
            </List>
        </Drawer>
    );
};

export default MenuDrawer;
