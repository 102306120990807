import React from "react";

import Footer from "../../components/Footer";
import HeaderImg from "../../components/HeaderImg";
import Module from "../../components/Module";
import TryIt from "../../components/TryIt";

import { messages as m } from '../../assets/messages/messages';
import Background from '../../assets/img/modulesBg.png';

const Modulos = () => {
    return (
        <>
            <HeaderImg title={m.modules} img={Background} />
            <Module />
            <TryIt />
            <Footer />
        </>
    );
};

export default Modulos;
