/* eslint-disable no-useless-computed-key */
import Background from '../../assets/img/dog-opacity.png';
import { createStyles } from '@material-ui/core';
import { colors as c } from '../../assets/colors/colors';
import { sizes as s } from '../../assets/sizes/sizes';

const plansCardsStyle = createStyles({
    cardsView: {
        display: 'flex',
        flexWrap: 'wrap',
        [`@media (max-width: ${s.tablet})`]: { display: 'block' },
        justifyContent: 'space-between',
        margin: '-45px 14% 10px 14%',
    },
    card: {
        backgroundColor: c.bgFaq,
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        borderRadius: '10px',
        width: '32%',
        marginTop: '10px',
        boxShadow: '0px 3px 5px 0px rgb(0 0 0 / 20%)',
        [`@media (max-width: ${s.tablet})`]: { width: '100%', marginBottom: '16px' },
    },
    cardWBg: {
        backgroundColor: c.bgFaq,
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        borderRadius: '10px',
        width: '32%',
        marginTop: '10px',
        boxShadow: '0px 3px 5px 0px rgb(0 0 0 / 20%)',
        backgroundImage: `url(${Background})`,
        backgroundPosition: 'left 98% bottom 5%',
        backgroundRepeat: 'no-repeat',
        [`@media (max-width: ${s.tablet})`]: { width: '100%', marginBottom: '16px' },
    },
    title: {
        fontSize: '18px',
        margin: '25px',
        textAlign: 'center',
        color: c.primary,
    },
    mainTitle: {
        fontSize: '20px',
        fontWeight: 600,
        margin: '25px',
        textAlign: 'center',
        color: c.primary,
    },
    logoContainer: {
        flexGrow: 1,
    },
    logo: {
        width: '130px',
    },
    price: {
        display: 'flex',
        alignItems: 'center',
        margin: '10px 0 15px 0',
    },
    boldPrice: {
        marginLeft: '8px',
        fontSize: '20px',
        fontWeight: 500,
    },
    inCashPrice: {
        marginBottom: '20px',
    },
    planItems: {
        padding: '15px',
    }
});

export default plansCardsStyle;
