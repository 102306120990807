/* eslint-disable no-useless-computed-key */
import { createStyles } from '@material-ui/core';
import { colors as c } from '../../assets/colors/colors';
import { sizes as s } from '../../assets/sizes/sizes';

const headerStyle = createStyles({
    base: {
        backgroundColor: c.white,
        [`@media (min-width: ${s.tablet})`]: { padding: '2px 45px' },
        [`@media (min-width: ${s.laptop})`]: { padding: '2px 90px' },
    },
    menuButton: {
        color: c.black,
        display: 'none',
        [`@media (max-width: ${s.tablet})`]: { display: 'flex' },
    },
    logoContainer: {
        flexGrow: 1,
    },
    logo: {
        width: '110px',
    },
});

export default headerStyle;
