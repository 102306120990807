import React from "react";

import Cards from "../../components/Cards";
import Faq from "../../components/FAQ";
import Footer from "../../components/Footer";
import Main from "../../components/Main";
import MainCard from "../../components/MainCard";
import TryIt from "../../components/TryIt";

const Home = () => {
    return (
        <>
            <Main />
            <div data-aos="fade-down">
                <Cards />
                <MainCard />
            </div>
            { /* <Faq /> */}
            <TryIt animate />
            <Footer />
        </>
    );
};

export default Home;
