/* eslint-disable no-useless-computed-key */
import { createStyles } from '@material-ui/core';
import { colors as c } from '../../assets/colors/colors';
import { sizes as s } from '../../assets/sizes/sizes';

const footerStyle = createStyles({
    footerView: {
        backgroundColor: c.bgFaq,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        padding: '34px 0',
    },
    img: {
        height: '35px',
    },
    icons: {
        display: 'flex',
        padding: '5px 0',
    },
    iconBall: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '50px',
        width: '50px',
        margin: '8px',
        borderRadius: '50%',
        backgroundColor: c.bgIcons,
        '&:hover': {
            backgroundColor: c.bgDarkColor,
        },
    },
    icon: {
        height: '24px',
    },
    links: {
        display: 'flex',
    },
    linkBox: {
        margin: '5px',
        color: c.black,
        textDecoration: 'none',
        textAlign: 'center',
        [`@media (max-width: ${s.mobileL})`]: { fontSize: '14px' },
        '&:hover': {
            textDecoration: 'underline',
        },
    },
});

export default footerStyle;
