import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import CardImg1 from '../../assets/img/card1.jpg';
import CardImg2 from '../../assets/img/card2.jpg';
import CardImg3 from '../../assets/img/card3.jpg';
import CardImg4 from '../../assets/img/card4.jpg';
import CardImg5 from '../../assets/img/card5.jpg';
import CardImg6 from '../../assets/img/card6.jpg';

import { messages as m } from '../../assets/messages/messages';
import styles from './cardsStyle';

const useStyles = makeStyles(styles);

const Cards = () => {
    const sty = useStyles();

    const cards: any = [
        {
            id: 0,
            name: m.card1Title,
            description: m.card1Description,
            image: CardImg1,
        },
        {
            id: 1,
            name: m.card2Title,
            description: m.card2Description,
            image: CardImg2,
        },
        {
            id: 2,
            name: m.card3Title,
            description: m.card3Description,
            image: CardImg3,
        },
        {
            id: 3,
            name: m.card4Title,
            description: m.card4Description,
            image: CardImg4,
        },
        {
            id: 4,
            name: m.card5Title,
            description: m.card5Description,
            image: CardImg5,
        },
        {
            id: 5,
            name: m.card6Title,
            description: m.card6Description,
            image: CardImg6,
        },
    ]

    return (
        <div className={sty.cardsView}>
            {cards.map((card: any) =>
                <div className={sty.card} key={card.id}>
                    <img className={sty.img} src={card.image} alt={''} />
                    <div className={sty.title}>
                        {card.name}
                    </div>
                    <div className={sty.description}>
                        {card.description}
                    </div>
                </div>
            )}
        </div>
    );
};

export default Cards;