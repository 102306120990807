import React from "react";

import Footer from "../../components/Footer";
import HeaderImg from "../../components/HeaderImg";
import TryIt from "../../components/TryIt";

import { messages as m } from '../../assets/messages/messages';
import Background from '../../assets/img/pricesBg.png';
import PlansCards from "../../components/PlansCards";

const Planos = () => {
    return (
        <>
            <HeaderImg title={m.pricesAndPlans} img={Background} />
            <div data-aos="fade-down">
                <PlansCards />
            </div>
            <TryIt />
            <Footer />
        </>
    );
};

export default Planos;
