/* eslint-disable no-useless-computed-key */
import { createStyles } from '@material-ui/core';
import { colors as c } from '../../assets/colors/colors';
import { sizes as s } from '../../assets/sizes/sizes';

const cardsStyle = createStyles({
    cardsView: {
        display: 'flex',
        flexWrap: 'wrap',
        [`@media (max-width: ${s.tablet})`]: { display: 'block' },
        justifyContent: 'space-between',
        margin: '-45px 14% 10px 14%',
    },
    card: {
        backgroundColor: c.white,
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        borderRadius: '10px',
        width: '32%',
        marginTop: '10px',
        [`@media (max-width: ${s.tablet})`]: { width: '100%', marginBottom: '16px' },
    },
    img: {
        width: '150px',
        height: '150px',
        [`@media (max-width: ${s.laptop})`]: { width: '120px', height: '120px' },
        marginTop: '15px',
        borderRadius: '50%',
    },
    title: {
        fontSize: '18px',
        fontWeight: 500,
        margin: '15px',
        textAlign: 'center',
    },
    description: {
        margin: '0 15px 15px 15px',
        textAlign: 'center',
    },
});

export default cardsStyle;
