import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';

import PrimaryButton from '../Buttons/PrimaryButton';
import Drawer from './Drawer';
import Tabs from './Tabs';
import Logo from "../../assets/img/petnobil-logo.png";

import { messages as m } from '../../assets/messages/messages';
import styles from './headerStyle';

const useStyles = makeStyles(styles);
interface OwnProps {
    current: string;
}

type Props = OwnProps;

const Header = ({ current }: Props) => {
    const [menuIsOpen, setMenuIsOpen] = useState<boolean>(false);
    const sty = useStyles();

    return (
        <>
            <AppBar elevation={1} position="static" className={sty.base}>
                <Toolbar>
                    <IconButton onClick={() => setMenuIsOpen(true)} edge="start" className={sty.menuButton} aria-label="menu">
                        <MenuIcon color="primary" />
                    </IconButton>
                    <div className={sty.logoContainer}>
                        <a href={m.homePath}>
                            <img className={sty.logo} src={Logo} alt="Petnobil logo" />
                        </a>
                    </div>
                    <Tabs current={current} />
                    <PrimaryButton />
                </Toolbar>
            </AppBar>
            <Drawer setMenuIsOpen={setMenuIsOpen} menuIsOpen={menuIsOpen} />
        </>
    );
};

export default Header;