export const messages: any = {
    //Header
    home: 'Home',
    homePath: '/Home',
    modules: 'Módulos',
    modulesPath: '/Modulos',
    pricesAndPlans: 'Planos e preços',
    pricesAndPlansPath: '/Planos',
    tryForFree: 'Experimente Grátis',
    tryForFreePath: '/Experimente',
    //Home
    homeTitleMessage1: 'Um novo conceito de gestão pet na palma da sua mão!',
    homeTitleMessage2st: 'Feito por quem entende d',
    homeTitleMessage2nd: 'o negócio',
    homeTitleMessage2sd: 'e petshop',
    card1Title: 'Sistema Online',
    card1Description: 'Acesse o sistema de onde estiver e tenha total controle sobre seu negócio. Sistema seguro e sempre disponível para você administrar o que é seu!',
    card2Title: 'Sem Taxa de Adesão',
    card2Description: 'Habilitamos o sistema sem cobrar nada por isso, habilite agora mesmo e comece a usar.',
    card3Title: 'Sem Multa de Cancelamento',
    card3Description: 'Se não estiver satisfeito, não se preocupe, cancele a qualquer momento sem qualquer ônus.',
    card4Title: 'Tecnologia de Ponta',
    card4Description: 'A solução combina tecnologia de nuvem, com alta disponibilidade e segurança, e outros elementos tecnológicos que trazem estabilidade, confiabilidade e velocidade ao sistema.',
    card5Title: 'Segurança',
    card5Description: 'Acesso seguro, dados seguros e backup diário automatizado.',
    card6Title: 'Experiência',
    card6Description: 'Tenha uma experiência diferenciada ao utilizar o sistema, com um visual moderno, sua usabilidade simples, intuitiva e autoexplicativa promovem uma perfeita navegação e utilização das funcionalidades do sistema.',
    mainCardTitle: 'Por que Petnobil?',
    mainCardDescription: 'O sistema foi construído com um único propósito: Facilitar o controle e a gestão tanto de petshop quanto de clínica veterinária baseado nas dificuldades reais enfrentadas por quem vivencia o dia a dia deste mercado. Foi feito por quem conhece! É tecnologia de ponta aplicada verdadeiramente ao negócio.',
    faq: 'Perguntas frequentes:',
    faqQuestion1: 'Pergunta 1',
    faqAnswer1: 'Resposta 1',
    faqQuestion2: 'Pergunta 2',
    faqAnswer2: 'Resposta 2',
    faqQuestion3: 'Pergunta 3',
    faqAnswer3: 'Resposta 3',
    faqQuestion4: 'Pergunta 4',
    faqAnswer4: 'Resposta 4',
    //Modules
    module1: 'Produto',
    module1Description: 'Gerencie seus produtos de maneira completa, cadastre e vincule categorias, controle e saiba quando seus produtos irão vencer, gerencie seu estoque e saiba o momento certo de comprar, faça a contagem e atualize seus estoque, saiba o que entrou e o que saiu, gere códigos de barras para identificar seus produtos, carregue seu estoque automaticamente baseado em sua compra, simplifique a gestão.',
    module2: 'Serviços',
    module2Description: 'Banho higiênico, tosa, transporte, internação, hotelzinho, exame, vacina, consulta, administre todo tipo de serviço que você deseja ofertar, gerencie o agendamento dos serviços, tenha o controle completo de sua agenda, gerencie comissões, administre as rotas de busca e entrega de animais.',
    module3: 'Cliente',
    module3Description: 'Fidelize seu cliente, conheça seu histórico de compras e serviços, seja pró ativo, antecipe suas necessidades e forneça um serviço único de qualidade.',
    module4: 'Animais',
    module4Description: 'Conheça o animalzinho, gerencie vacinas, banhos e tosas, rações, saiba o momento certo de entrar em contato com seu cliente para lembrá-lo do que ele precisa.',
    module5: 'Veterinário',
    module5Description: 'Gerencie sua agenda veterinária, controle as consultas, administre o exame clínico do animal, diagnóstico, prognóstico, prescrição e exames. Administre as vacinas, agende retornos e muito mais.',
    module6: 'Internação',
    module6Description: 'O painel de internação permite um gerenciamento muito eficiente, desde de o agendamento, passando pela entrada do animal, até o controle de todos os serviços e produtos administrados durante a estadia, saiba a  localização do animal, gerencie a previsão de alta.',
    module7: 'Fornecedores',
    module7Description: 'Cadastre seus fornecedores e comece a analisar e entender o valor agregado que cada um te entrega. Registre seus pedidos de compra, administre valores de frete e previsões de entrega, registre sua lista de desejo. Alimente seu estoque de forma automática na chegada dos seus produtos.',
    module8: 'Colaboradores',
    module8Description: 'O sistema permite criar usuários e atrelar permissões específicas de acesso e com isso você consegue liberar somente o que é necessário para cada usuário. Faça isso através de perfis de acesso. Saiba quais colaboradores executam os serviços que você oferece, defina e gerencie comissões.',
    module9: 'Venda',
    module9Description: 'Realize suas vendas de forma rápida e eficiente, atrele produtos e serviços, formas de pagamentos diversas, e alimente seus recebíveis. Gerencie vendas simultâneas e não deixe seu cliente esperando a finalização do atendimento.',
    module10: 'Despesas',
    module10Description: 'Registre suas despesas, crie categorias e recorrências de pagamento, saiba quais compromissos de pagamento você tem, otimize seu tempo e faça a gestão financeira do seu negócio.',
    module11: 'Caixa',
    module11Description: 'Controle entrada e saída do caixa e entenda a movimentação do seu dinheiro.',
    module12: 'Gestão e Controle',
    module12Description: 'Você sabe exatamente quantos e quais produtos foram vendidos hoje? Ou quais serviços foram realizados hoje? Quem comprou ou realizou? Por qual preço? Teve desconto? De quanto foi? Qual o valor faturado hoje? Sob quais formas de pagamento? Quanto você tem de receita para receber hoje, na semana, no mês, nos próximos meses? Quanto você tem de despesas para gerenciar? Você tem a visão da evolução financeira do seu negócio, você tem a visão de apuração do resultado mensal do seu negócio? Você sabe quanto precisa pagar de comissionamento para seus funcionários? Você sabe qual funcionário é mais produtivo? Você controla seu estoque? Faz inventário? Quanto tempo leva? Você conhece os produtos que não tem movimentação? Você conhece seu estoque e sabe o que e qual momento fazer um novo pedido? Você conhece seu cliente e seu animalzinho? Sabe do que ele precisa e quando precisa? Sabe quais são seus melhores clientes? Sabe quando a ração do animalzinho está acabando? Sabe o momento de lembrá-lo sobre as vacinas? Consegue ter a visão clara do prontuário clínico do animal e seu histórico de saúde? Estas são algumas de várias outras importantes perguntas que precisam de respostas, e que somente quem vive e conhece do negócio e deste ramo Pet é capaz de entender, e é por isso que o Petnobil é o sistema ideal para você, com ele você aumentará suas chances de prosperar o seu negócio!',
    //Plans and pricing
    divide: '12x',
    inCash: 'À vista',
    namePlan1: 'PREMIUM',
    pricePlan1: 'R$0,00',
    pricePlan1InCash: 'R$0,00',
    namePlan2: 'PREMIUM PLUS',
    pricePlan2: 'R$0,00',
    pricePlan2InCash: 'R$0,00',
    namePlan3: 'MAX',
    pricePlan3: 'R$0,00',
    pricePlan3InCash: 'R$0,00',
    item1Description: 'Item 1 descrição',
    item2Description: 'Item 2 descrição',
    item3Description: 'Item 3 descrição',
    item4Description: 'Item 4 descrição',
    item5Description: 'Item 5 descrição',
    //Try for free
    tryForFreeTitle: 'TESTE GRATUITAMENTE',
    tryFfLine1: 'Experimente Petnobil em seu negócio.',
    tryFfLine2: 'Teste gratuitamente por 15 dias. Não precisa de cartão de crédito!',
    tryFfLine3: 'Entre em contato através do e-mail',
    name: 'Nome',
    mail: 'E-mail',
    phone: 'Telefone',
    //Social
    petnobilMail: '',
    petnobilFacebook: '',
    petnobilInstagram: 'https://www.instagram.com/petnobil/',
    petnobilLinkedin: '',
    petnobilTwitter: '',
};