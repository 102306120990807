/* eslint-disable no-useless-computed-key */
import { createStyles } from '@material-ui/core';
import { colors as c } from '../../assets/colors/colors';
import { sizes as s } from '../../assets/sizes/sizes';

const cardsStyle = createStyles({
    mainCardView: {
        [`@media (max-width: ${s.tablet})`]: { display: 'block' },
        justifyContent: 'space-between',
        padding: '1% 14% 0 14%',
    },
    card: {
        justifyContent: 'space-between',
        padding: '2%',
        border: `2px solid ${c.white}`,
        borderRadius: '10px',
    },
    title: {
        fontSize: '18px',
        fontWeight: 500,
        margin: '15px',
        textAlign: 'center',
    },
    description: {
        margin: '0 15px 15px 15px',
        textAlign: 'center',
    },
});

export default cardsStyle;
