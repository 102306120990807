import { makeStyles } from '@material-ui/core/styles';
import styles from './planItemStyle';
import IcoEnabled from '../../../assets/img/ico-enabled.png';
import IcoDisabled from '../../../assets/img/ico-disabled.png';

const useStyles = makeStyles(styles);

interface OwnProps {
    item: any;
    disabled: string;
}

type Props = OwnProps;

const PlanItem = ({ item, disabled }: Props) => {
    const sty = useStyles();

    return (
        <div className={disabled ? sty.planItemDisabled : sty.planItem}>
            <img className={sty.ico} src={disabled ? IcoDisabled : IcoEnabled} alt='' />
            {item.itemDescription}
        </div>
    );
};

export default PlanItem;