import imgModule1 from '../../assets/img/module1.jpg';
import imgModule2 from '../../assets/img/module2.jpg';
import imgModule3 from '../../assets/img/module3.jpg';
import imgModule4 from '../../assets/img/module4.jpg';
import imgModule5 from '../../assets/img/module5.jpg';
import imgModule6 from '../../assets/img/module6.jpg';
import imgModule7 from '../../assets/img/module7.jpg';
import imgModule8 from '../../assets/img/module8.jpg';
import imgModule9 from '../../assets/img/module9.png';
import imgModule10 from '../../assets/img/module10.jpg';
import imgModule11 from '../../assets/img/module11.jpg';
import imgModule12 from '../../assets/img/module12.jpg';

import { messages as m } from '../../assets/messages/messages';

export const modules: any = [
    {
        id: 0,
        name: m.module1,
        description: m.module1Description,
        image: imgModule1,
        align: 'right',
    },
    {
        id: 1,
        name: m.module2,
        description: m.module2Description,
        image: imgModule2,
        align: 'left',
    },
    {
        id: 2,
        name: m.module3,
        description: m.module3Description,
        image: imgModule3,
        align: 'right',
    },
    {
        id: 3,
        name: m.module4,
        description: m.module4Description,
        image: imgModule4,
        align: 'left',
    },
    {
        id: 4,
        name: m.module5,
        description: m.module5Description,
        image: imgModule5,
        align: 'right',
    },
    {
        id: 5,
        name: m.module6,
        description: m.module6Description,
        image: imgModule6,
        align: 'left',
    },
    {
        id: 6,
        name: m.module7,
        description: m.module7Description,
        image: imgModule7,
        align: 'right',
    },
    {
        id: 7,
        name: m.module8,
        description: m.module8Description,
        image: imgModule8,
        align: 'left',
    },
    {
        id: 8,
        name: m.module9,
        description: m.module9Description,
        image: imgModule9,
        align: 'right',
    },
    {
        id: 9,
        name: m.module10,
        description: m.module10Description,
        image: imgModule10,
        align: 'left',
    },
    {
        id: 10,
        name: m.module11,
        description: m.module11Description,
        image: imgModule11,
        align: 'right',
    },
    {
        id: 11,
        name: m.module12,
        description: m.module12Description,
        image: imgModule12,
        align: 'left',
    },
]