/* eslint-disable no-useless-computed-key */
import { createStyles } from '@material-ui/core';
import { colors as c } from '../../assets/colors/colors';
import { sizes as s } from '../../assets/sizes/sizes';

const moduleStyle = createStyles({
    cardsView: {
        display: 'flex',
        flexWrap: 'wrap',
        [`@media (max-width: ${s.tablet})`]: { display: 'block' },
        justifyContent: 'space-between',
        margin: '-65px 14% 10px 14%',
    },
    card: {
        padding: '10px',
        backgroundColor: c.white,
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        [`@media (min-width: ${s.tablet})`]: { flexDirection: 'row' },
        borderRadius: '10px',
        width: '100%',
        marginTop: '10px',
        [`@media (max-width: ${s.tablet})`]: { width: '93%', marginBottom: '16px' },
    },
    img: {
        height: '150px',
        [`@media (max-width: ${s.laptop})`]: { height: '120px' },
        borderRadius: '5px',
    },
    tDesc: {
        margin: '15px',
    },
    title: {
        fontSize: '18px',
        fontWeight: 500,
        marginBottom: '5px',
        textAlign: 'left',
    },
    description: {
        textAlign: 'left',
    },
});

export default moduleStyle;
