/* eslint-disable no-useless-computed-key */
import { createStyles } from '@material-ui/core';
import { colors as c } from '../../../assets/colors/colors';

const planItemStyle = createStyles({
    ico: {
        marginRight: '10px',
    },
    planItem: {
        color: c.black,
        display: 'flex',
        alignItems: 'center',
        marginBottom: '5px',
    },
    planItemDisabled: {
        color: c.mediumGray,
        marginBottom: '5px',
    },
});

export default planItemStyle;
