import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { messages as m } from '../../assets/messages/messages';
import Logo from "../../assets/img/petnobil-logo.png";

import styles from './plansCardsStyle';
import PlanItem from './PlanItem';

const useStyles = makeStyles(styles);

const PlansCards = () => {
    const sty = useStyles();

    const cards: any = [
        {
            id: 0,
            name: m.namePlan1,
            price: m.pricePlan1,
            inCashPrice: m.pricePlan1InCash,
            itens: [
                {
                    id: 0,
                    itemDescription: m.item1Description,
                    disabled: false,
                },
                {
                    id: 1,
                    itemDescription: m.item2Description,
                    disabled: false,
                },
                {
                    id: 2,
                    itemDescription: m.item3Description,
                    disabled: false,
                },
                {
                    id: 3,
                    itemDescription: m.item4Description,
                    disabled: true,
                },
                {
                    id: 4,
                    itemDescription: m.item5Description,
                    disabled: true,
                }
            ]
        },
        {
            id: 1,
            name: m.namePlan2,
            price: m.pricePlan2,
            inCashPrice: m.pricePlan2InCash,
            itens: [
                {
                    id: 0,
                    itemDescription: m.item1Description,
                    disabled: false,
                },
                {
                    id: 1,
                    itemDescription: m.item2Description,
                    disabled: false,
                },
                {
                    id: 2,
                    itemDescription: m.item3Description,
                    disabled: false,
                },
                {
                    id: 3,
                    itemDescription: m.item4Description,
                    disabled: false,
                },
                {
                    id: 4,
                    itemDescription: m.item5Description,
                    disabled: true,
                }
            ]
        },
        {
            id: 2,
            name: m.namePlan3,
            price: m.pricePlan3,
            inCashPrice: m.pricePlan3InCash,
            itens: [
                {
                    id: 0,
                    itemDescription: m.item1Description,
                    disabled: false,
                },
                {
                    id: 1,
                    itemDescription: m.item2Description,
                    disabled: false,
                },
                {
                    id: 2,
                    itemDescription: m.item3Description,
                    disabled: false,
                },
                {
                    id: 3,
                    itemDescription: m.item4Description,
                    disabled: false,
                },
                {
                    id: 4,
                    itemDescription: m.item5Description,
                    disabled: false,
                }
            ]
        },
    ]

    return (
        <div className={sty.cardsView}>
            {cards.map((card: any) =>
                <div className={card.id === 1 ? sty.cardWBg : sty.card} key={card.id}>
                    <div className={card.id === 1 ? sty.mainTitle : sty.title}>
                        {card.name}
                    </div>
                    <div className={sty.logoContainer}>
                        <a href={m.homePath}>
                            <img className={sty.logo} src={Logo} alt="Petnobil logo" />
                        </a>
                    </div>
                    <div className={sty.price}>
                        <div>{m.divide}</div>
                        <div className={sty.boldPrice}>{card.price}</div>
                    </div>
                    <div className={sty.inCashPrice}>
                        {`${m.inCash} ${card.inCashPrice}`}
                    </div>
                    <div className={sty.planItems}>
                        {card.itens.map((item: any) =>
                            <PlanItem item={item} disabled={item.disabled} />
                        )}
                    </div>
                </div>
            )}
        </div>
    );
};

export default PlansCards;