import React from "react";
import { Route, Switch, Redirect, useLocation } from "react-router-dom";
import Home from "../views/home";
import Header from "../components/Header";
import Modulos from "../views/modulos";
import Planos from "../views/planos";
import Experimente from "../views/experimente";

import { messages as m } from "../assets/messages/messages";

const Routes = () => {
    const location = useLocation();
    let current = location.pathname;

    return (
        <>
            <Header current={current} />
            <Switch>
                <Route path={m.homePath} component={Home} />
                <Route path={m.modulesPath} component={Modulos} />
                <Route path={m.pricesAndPlansPath} component={Planos} />
                <Route path={m.tryForFreePath} component={Experimente} />
                <Route render={() => <Redirect to={m.homePath} />} />
            </Switch>
        </>
    );
};

export default Routes;
