import React from "react";
import { Router } from "react-router-dom";
import Routes from "./routes";
import history from "./routes/history";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import "./App.css";

const theme = createMuiTheme({
    palette: {
        primary: {
            main: "#21A0CE",
        },
        secondary: {
            main: "#148FBC",
        },
    },
});

function App() {
    return (
        <MuiThemeProvider theme={theme}>
            <Router history={history}>
                <Routes />
            </Router>
        </MuiThemeProvider>
    );
}

export default App;