import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Logo from '../../assets/img/petnobil-logo.png';
import Mail from '../../assets/img/mail.png';
import Facebook from '../../assets/img/facebook.png';
import Instagram from '../../assets/img/instagram.png';
import Linkedin from '../../assets/img/linkedin.png';
import Twitter from '../../assets/img/twitter.png';

import { messages as m } from '../../assets/messages/messages';
import styles from './footerStyle';

const useStyles = makeStyles(styles);

const Footer = () => {
    const sty = useStyles();

    const icons: any = [
        {
            id: 0,
            icon: Mail,
            url: m.petnobilMail,
        },
        {
            id: 1,
            icon: Facebook,
            url: m.petnobilFacebook,
        },
        {
            id: 2,
            icon: Instagram,
            url: m.petnobilInstagram,
        },
        {
            id: 3,
            icon: Linkedin,
            url: m.petnobilLinkedin,
        },
        {
            id: 4,
            icon: Twitter,
            url: m.petnobilTwitter,
        },
    ]

    const menuItens: any = [
        {
            id: 0,
            name: m.home,
            path: m.homePath,
        },
        {
            id: 1,
            name: m.modules,
            path: m.modulesPath,
        },
        /*
        {
            id: 2,
            name: m.pricesAndPlans,
            path: m.pricesAndPlansPath,
        },*/
        {
            id: 3,
            name: m.tryForFree,
            path: m.tryForFreePath,
        }
    ]

    return (
        <div className={sty.footerView}>
            <div>
                <img className={sty.img} src={Logo} alt={''} />
            </div>
            <div>
                <strong>contato@tecnobil.com.br</strong>
            </div>

            { /*
            <div className={sty.icons}>
                {icons.map((icon: any) =>
                    <div className={sty.icons}>
                        <a href={icon.url}>
                            <div className={sty.iconBall}>
                                <img className={sty.icon} src={icon.icon} alt='' />
                            </div>
                        </a>
                    </div>
                )}
            </div>
            */ }

            <div className={sty.links}>
                {menuItens.map((item: any) =>
                    <div className={sty.links}>
                        <a className={sty.linkBox} href={item.path}>
                            {item.name}
                        </a>
                    </div>
                )}
            </div>
        </div>
    );
};

export default Footer;