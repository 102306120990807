import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { messages as m } from '../../assets/messages/messages';
import styles from './mainCardStyle';


const useStyles = makeStyles(styles);

const MainCard = () => {
    const sty = useStyles();

    return (
        <div className={sty.mainCardView}>
            <div className={sty.card}>
                <div className={sty.title}>
                    {m.mainCardTitle}
                </div>
                <div className={sty.description}>
                    {m.mainCardDescription}
                </div>
            </div>
        </div>
    );
};

export default MainCard;