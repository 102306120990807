import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import PrimaryButton from '../Buttons/PrimaryButton';
import Typist from 'react-text-typist';

import { messages as m } from '../../assets/messages/messages';
import styles from './mainStyle';


const useStyles = makeStyles(styles);

const Main = () => {
    const sty = useStyles();

    return (
        <div className={sty.main}>
            <div className={sty.middle}>
                <div className={sty.title}>
                    {m.homeTitleMessage1}
                </div>
                <div className={sty.type}>
                    <span className={sty.subTitle}>
                        {m.homeTitleMessage2st}
                    </span>
                    <Typist cursorBlinkSpeed={1000} pauseTime={2000} typingSpeed={80} deletingSpeed={50} className={sty.subTitle} sentences={[`${m.homeTitleMessage2nd}`, `${m.homeTitleMessage2sd}`]} loop={true} />
                </div>
                <Divider className={sty.bgDivider} />
                <PrimaryButton bigger />
            </div>
        </div>
    );
};

export default Main;