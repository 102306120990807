export const sizes: any = {
    //Mobile
    mobileS: '320px',
    mobileM: '375px',
    mobileL: '425px',
    //Tablet
    tablet: '768px',
    //Laptop
    laptop: '1024px',
    laptopL: '1440px',
    //Desktop
    desktop: '2560px',
};