import { makeStyles } from '@material-ui/core/styles';
import { Link } from "react-router-dom";

import { messages as m } from '../../../assets/messages/messages';
import styles from './tabStyle';

const useStyles = makeStyles(styles);

interface OwnProps {
    current: string;
}

type Props = OwnProps;

const Tabs = ({ current }: Props) => {
    const sty = useStyles();

    const tabs: any = [
        {
            id: 0,
            name: m.home,
            path: m.homePath,
        },
        {
            id: 1,
            name: m.modules,
            path: m.modulesPath,
        }
        /*,
        {
            id: 2,
            name: m.pricesAndPlans,
            path: m.pricesAndPlansPath,
        },
        */
    ]

    return (
        <div className={sty.tabView}>
            {tabs.map((item: any) =>
                <Link className={sty.link} to={item.path}>
                    <div className={current === item.path ? sty.dot : sty.dotHidden} />
                    <div className={current === item.path ? `${sty.tab} ${sty.tabActive}` : `${sty.tab} ${sty.tabInactive}`}>
                        {item.name}
                    </div>
                </Link>
            )}
        </div>
    );
};

export default Tabs;