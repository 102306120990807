import { createStyles } from '@material-ui/core';

const buttonStyle = createStyles({
    button: {
        borderRadius: '50px',
        textTransform: 'inherit',
    },
    normal: {
        padding: '8px 16px',
        fontSize: '15px',
    },
    bigger: {
        padding: '8px 24px',
        fontSize: '18px',
    }
});

export default buttonStyle;
