import { colors as c } from '../../../assets/colors/colors';

const drawerStyle = {
    list: {
        width: 250,
        backgroundColor: c.white,
        height: '100vh',
        color: c.black,
        fontWeight: 300,
    },
    white: {
        color: '#484848',
        display: 'flex',
        height: '55px',
        '&,&:hover,&:focus': {
            color: c.black,
            textDecoration: 'none',
        },
    },
    bgDivider: {
        backgroundColor: c.lightGray,
    },
};

export default drawerStyle;
