import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { modules } from './modules';

import styles from './moduleStyle';

const useStyles = makeStyles(styles);

const Module = () => {
    const sty = useStyles();

    return (
        <div className={sty.cardsView} data-aos="fade-down">
            {modules.map((card: any) =>
                <div className={sty.card} key={card.id} data-aos="fade-down">
                    <img className={sty.img} src={card.image} alt={''} />
                    <div className={sty.tDesc}>
                        <div className={sty.title}>
                            {card.name}
                        </div>
                        <div className={sty.description}>
                            {card.description}
                        </div>
                    </div>
                </div>
            )}

        </div>
    );
};

export default Module;