import React from "react";

import Footer from "../../components/Footer";
import HeaderImg from "../../components/HeaderImg";
import TryIt from "../../components/TryIt";

import { messages as m } from '../../assets/messages/messages';
import Background from '../../assets/img/tryItBg.png';

const Experimente = () => {
    return (
        <>
            <HeaderImg title={m.tryForFree} img={Background} />
            <TryIt />
            <Footer />
        </>
    );
};

export default Experimente;
