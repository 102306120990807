/* eslint-disable no-useless-computed-key */
import Background from '../../assets/img/tryBg.png';
import { createStyles } from '@material-ui/core';
import { colors as c } from '../../assets/colors/colors';
import { sizes as s } from '../../assets/sizes/sizes';

const tryItStyle = createStyles({
    tryItView: {
        backgroundImage: `url(${Background})`,
        backgroundSize: 'cover',
        padding: '30px 14%',
        justifyContent: 'space-between',
        alignItems: 'center',
        display: 'flex',
        [`@media (max-width: ${s.tablet})`]: { display: 'block' },
    },
    imgView: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: '10px',
    },
    img: {
        height: '370px',
        [`@media (min-width: ${s.laptop}) and (max-width: ${s.laptopL})`]: { height: '250px' },
        [`@media (min-width: ${s.mobileL}) and (max-width: ${s.laptop})`]: { height: '140px' },
        [`@media (min-width: ${s.mobileM}) and (max-width: ${s.mobileL})`]: { height: '140px' },
        [`@media (max-width: ${s.mobileS})`]: { height: '120px' },
    },
    formView: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: '380px',
        [`@media (max-width: ${s.mobileL})`]: { height: '420px' },
    },
    title: {
        fontSize: '20px',
        fontWeight: 500,
    },
    subtitle: {
        fontSize: '16px',
        lineHeight: 1.8,
        width: '100%',
    },
    fieldsView: {
        fontSize: '18px',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    input: {
        backgroundColor: c.bgFaq,
        height: '50px',
        borderRadius: '5px',
        border: 'none',
        margin: '5px 0',
        padding: '0 15px',
        fontSize: '16px',
        '&,&:hover,&:focus': {
            outline: 'none',
        },
    }
});

export default tryItStyle;
